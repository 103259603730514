






























































































import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
@Component({
  name: "Dashboard",
})
export default class Dashboard extends Vue {
  private drawer = true;
  private title = "IranSshVPN";
  private fixed = false;
  getNotif() {
    this.$router.push("/device-notifications");
  }
  private items = [
    {
      icon: "mdi-home",
      title: "صفحه اصلی",
      to: "/dashboard/sshkeys",
      adminVisible: true,
      permission: "Home_Show",
    },
   
    {
      icon: "mdi-account",
      title: "مدیریت کاربران",
      adminVisible: this.$store.state.userDetails.isAdmin,
      permission: "Member_Show",
      to: "/dashboard/users",
    },

    {
      icon: "mdi-currency-usd ",
      title: "تراکنش ها",
      permission: "Role_Show",
      to: "/dashboard/orders",
    },

    // {
    //   icon: "mdi-email ",
    //   title: "مدیریت تیکتها",
    //   permission: "Role_Show",
    //   to: "/dashboard/manage-tickets",
    // },
    // {
    //   icon: "mdi-lifebuoy",
    //   title: "پشتیبانی",
    //   permission: "Role_Show",
    //   to: "/dashboard/tickets",
    // },
    // {
    //   icon: "mdi-alert-box",
    //   title: "آموزش اتصال با ایفون و اندروید",
    //   to: "/dashboard/phone-toturial",
    // },
    // {
    //   icon: "mdi-alert-box",
    //   title: "آموزش اتصال ویندوز",
    //   to: "/dashboard/windows-toturial",
    // },
    // {
    //   icon: "mdi-alert-box",
    //   title: "اموزش اتصال لینوکس",
    //   to: "/dashboard/linux-toturial",
    // },
   
    // {
    //   icon: "mdi-server",
    //   title: "سرورها",
    //   adminVisible: true,
    //   permission: "ApiUrl_Show",
    //   to: "/dashboard/v2servers",
    // },
    {
      icon: "mdi-key",
      title: "کلیدها",
      adminVisible: true,

      permission: "ApiUrl_Show",
      to: "/dashboard/sshkeys",
    },

    // {
    //   icon: "mdi-currency-usd",
    //   title: "خرید ترافیک",
    //   adminVisible: true,
    //   permission: "Member_Show",
    //   to: "/buy-traffic",
    // },
    {
      icon: "mdi-account-edit",
      title: "پروفایل",
      permission: "Profile_Show",
      to: "/dashboard/profile",
      adminVisible: true,
    },
  ];
  get currentUser() {
    return UserModule.fullName;
  }

  private logout = () => {
    UserModule.ResetToken();
    localStorage.removeItem("permissions");
    location.reload();
  };
}
